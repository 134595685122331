import { Col, Dropdown, MenuProps, Row, Typography,Button } from "antd"
import { SvgIcon } from "../../../../../shared/icons"
import "./index.scss"
import { logout } from "../../../../../api/logout"
import { useNavigate } from "react-router-dom"


export const AccountDropdown = () => {
  const navigate = useNavigate()
  const storedDataString = localStorage.getItem('user-data'); // Retrieve the stored JSON string

  if (storedDataString !== null) {
    try {
      const storedData = JSON.parse(storedDataString); // Parse the JSON string
      // Now you can use storedData safely
      console.log("Parsed JSON data:", storedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  } else {
    console.error("No 'user-data' found in localStorage");
  }
  const items: MenuProps["items"] = [
    {
      label: (
        <Button
        type={"link"}
        className={"account-dropdown-btn"}
        onClick={() =>
          logout()
            .then(() => navigate("./login"))
            .catch(e => console.log(e))
        }
      >
        Logout
      </Button>
      ),
      key: "0",
    },
    // {
    //   type: "divider",
    // },
    // {
    //   label: "Option 2",
    //   key: "1",
    // },
  ]
  return (
    <Dropdown className={"account-dropdown"} menu={{ items }} trigger={["click"]}>
      <Row align={"middle"}>
        <Col>
        {storedDataString != null? (<>
          <Typography.Paragraph className={"email"}>
          {JSON.parse(storedDataString).email}
           </Typography.Paragraph>
           <Typography.Paragraph className={"name"}>
           {JSON.parse(storedDataString).name}
           </Typography.Paragraph>
        </>) : (<></>)}
         
        </Col>
        <SvgIcon className={"arrow-icon"} type={"arrowExpand"} width={12} height={12} />
      </Row>
    </Dropdown>
  )
}
