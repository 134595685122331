import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Header } from "./Header"

export const Layout: FC = () => (
  <BaseLayout style={{ display: "flex", flexDirection: "column", alignItems: "stretch", minHeight: "100vh" }}>
    <BaseLayout.Header>
      <Header />
    </BaseLayout.Header>
    <BaseLayout style={{ padding: "0", backgroundColor: "#F8FAFF" }}>
      <Suspense>
        <Outlet />
      </Suspense>
    </BaseLayout>
  </BaseLayout>
)
