import { FC } from "react"
import { Link } from "react-router-dom"
import { SvgIcon } from "../../../shared/icons"

interface LogoProps {
  width?: number | string
  height?: number | string
  isLink?: boolean
}

const Logo: FC<LogoProps> = ({ width, height, isLink = true, ...props }) => {
  return isLink ? (
    <Link to={"/"} {...props} style={{ display: "flex" }}>
      <SvgIcon type={"logo"} width={width || 67} height={height || 22} />
    </Link>
  ) : (
    <SvgIcon type={"logo"} width={width || 67} height={height || 22} {...props} />
  )
}

export { Logo }
