import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "'Inter', sans-serif",
    colorText: "#181818",
  },
  components: {
    Layout: {
      headerPadding: "20px 60px",
      headerBg: "#FFF",
      bodyBg: "transparent",
      footerPadding: "20px 60px",
      footerBg: "transparent",
      colorBgLayout: "transparent",
    },
    Button: {
      paddingBlock: 11,
      paddingInline: 16,
      borderRadius: 8,
      fontSize: 14,
      fontWeight: 500,
      primaryColor: "#FFF",
      primaryShadow: "unset",
      colorPrimary: "#4480FF",
      colorPrimaryBorder: "#4480FF",
    },
    Table: {
      borderRadiusLG: 0,
    },
  },
}

export default theme
