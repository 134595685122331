import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const isAuthenticated = () => {
  return localStorage.getItem('jwt') !== null;
};

export const PrivateRoute: FC = () => {
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
};
