import { FC, lazy } from "react";
import { RouteObject } from "react-router-dom";

import { Layout } from "../components/layout";
import { Loader } from "../components/ui/Loader";
import { PublicRoute } from "../components/ui/PublicRoute"
import { PrivateRoute } from "../components/ui/PrivateRoute"

const Home = lazy<FC>(() => import("./home"));
const Login = lazy<FC>(() => import("./login"));
const ForgetPassword = lazy<FC>(() => import("./forgot-password"));
const ResetPassword = lazy<FC>(() => import("./reset-password"));

const routes: RouteObject[] = [
  {
    Component: PublicRoute,
    children: [
      {
        Component: Login,
        loader: () => <Loader spinning />,
        path: "/login",
      },
      {
        Component: ForgetPassword,
        loader: () => <Loader spinning />,
        path: "/forgot-password",
      },
      {
        Component: ResetPassword,
        loader: () => <Loader spinning />,
        path: "/reset-password",
      },
    ],
  },
  {
    Component: PrivateRoute,
    children: [
      {
        Component: Layout,
        loader: () => <Loader spinning />,
        children: [
          {
            Component: Home,
            path: "/",
            index: true,
          },
        ],
      },
    ],
  },
];

export default routes;
