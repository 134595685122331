import { Col, ColProps } from "antd"
import { CSSProperties, FC, ReactNode } from "react"
import "./index.scss"

interface ContainerProps extends ColProps {
  style?: CSSProperties
  className?: string
  children?: ReactNode
}

export const Container: FC<ContainerProps> = ({ style, className, children, ...props }) => {
  return (
    <Col className={className ? `container ${className}` : "container"} style={style} {...props}>
      {children}
    </Col>
  )
}
