import { FC, SVGProps } from "react"
import { ReactComponent as ArrowExpand } from "./arrow-expand.svg"
import { ReactComponent as Delete } from "./delete.svg"
import { ReactComponent as Download } from "./download.svg"
import { ReactComponent as Edit } from "./edit.svg"
import { ReactComponent as Error } from "./error.svg"
import { ReactComponent as Facebook } from "./facebook.svg"
import { ReactComponent as Instagram } from "./instagram.svg"
import { ReactComponent as Logo } from "./logo.svg"
import { ReactComponent as SortAll } from "./sort-all.svg"
import { ReactComponent as X } from "./x.svg"
import { ReactComponent as Youtube } from "./youtube.svg"
import { ReactComponent as EyeOpened } from "./eye-opened.svg"
import { ReactComponent as EyeClosed } from "./eye-closed.svg"
import { ReactComponent as Exclamation } from "./exclamation.svg"
import { ReactComponent as Arrow } from "./arrow.svg"

type SvgIconProps = SVGProps<SVGSVGElement> & { type: keyof typeof icons }

const icons = {
  arrow: Arrow,
  arrowExpand: ArrowExpand,
  delete: Delete,
  download: Download,
  edit: Edit,
  error: Error,
  eyeOpened: EyeOpened,
  eyeClosed: EyeClosed,
  exclamation: Exclamation,
  facebook: Facebook,
  instagram: Instagram,
  logo: Logo,
  sortAll: SortAll,
  youtube: Youtube,
  x: X,
}

export const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type]

  return <Icon {...svgProps} />
}
