import axios from "axios"
import { baseHeaders, baseUrl } from "./baseUrl"

export const logout = (): Promise<false | void> => {
  return axios
    .post(
      `${baseUrl}logout`,
      {},
      {
        headers: { ...baseHeaders, Authorization: `Bearer ${localStorage.getItem("jwt")}` },
      }
    )
    .then(response => response.data.status === 1 && localStorage.removeItem("jwt"))
    .catch(error => console.log("error:", error.message))
}
