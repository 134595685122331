import { Row } from "antd"
import { Container } from "../../ui/Container"
import { Logo } from "../../ui/Logo"
import "./index.scss"
import { AccountDropdown } from "./ui/AccountDropdown"

export const Header = () => {
  return (
    <Container className={"header-content"}>
      <Row justify={"space-between"} align={"middle"}>
        <Logo />
        <AccountDropdown />
      </Row>
    </Container>
  )
}
